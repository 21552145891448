export default {
	SET_RESULTS(state, data) {
		state.results = data
	},

	SET_CHECKBOX_FACETS(state, data) {
		const resultArray = []
		const facets = ['jobUmfaenge/jobUmfangName']

		Object.entries(data).forEach((element) => {
			if (facets.includes(element[0])) {
				resultArray.push({
					field: element[0],
					values: element[1],
				})
			}
		})
		state.checkbox_facets = resultArray
	},

	SET_DROPDOWN_FACETS(state, data) {
		const resultArray = []
		const facets = ['arbeitsbereiche/arbeitsbereich']

		Object.entries(data).forEach((element) => {
			if (facets.includes(element[0])) {
				resultArray.push({
					field: element[0],
					values: element[1],
				})
			}
		})
		state.dropdown_facets = resultArray
	},

	SET_SEARCHSTRING(state, data) {
		state.searchString = data
	},
	SET_SEARCHSTRING_DISTANCE(state, data) {
		state.searchStringDistance = data
	},

	SET_FILTERS(state, payload) {
		if (payload.selected == null) {
			delete state.filters[payload.facet]
		} else {
			state.filters[payload.facet] = Array.isArray(payload.selected)
				? payload.selected
				: payload.selected.split()
		}

		const keys = Object.keys(state.filters, state.filterRadius)

		// city have to be selected by clicking on autocomplete result
		if (typeof state.searchStringDistance.lng === 'undefined') {
			return false
		}

		let _queryString = ''

		// set default filterStringDistance: only if someone explicit remove the distance filter, it won't use the 25 km. but when the page loads for first time, it will set the 25 km
		if (
			state.filterStringDistance === '' ||
			state.filterStringDistance === null
		) {
			state.filterStringDistance = '25'
		}

		// don't search by lat and lng if no filterStringDistance was found + maybe someone will remove the default filterStringDistance so better check it
		if (
			state.filterStringDistance !== null &&
			state.filterStringDistance !== ''
		) {
			_queryString =
				_queryString +
				`(jobOrte/any (job: geo.distance(job/location, geography'POINT(${state.searchStringDistance.lng} ${state.searchStringDistance.lat})') le ${state.filterStringDistance}))`
		}

		let _filterTypes = []

		keys.map((key) => {
			state.filters[key].map((selectedValue) => {
				// organize or-conditions
				Object.keys(state.filters).forEach(function (filterType) {
					let _filterNameCollection = []
					let _filterTypeSplit = filterType.split('/')

					Object.keys(state.filters[filterType]).forEach(function (
						filterName
					) {
						_filterNameCollection.push(
							'(' +
								_filterTypeSplit[0] +
								'/any (job: job/' +
								_filterTypeSplit[1] +
								" eq '" +
								state.filters[filterType][filterName] +
								"'))"
						)
					})

					if (_filterNameCollection.length === 0) {
						return
					}

					_filterTypes[filterType] =
						'(' + _filterNameCollection.join(' or ') + ')'
				})
			})
		})

		// adding other filters
		Object.keys(_filterTypes).forEach(function (element) {
			let _and = ''
			if (_queryString !== '') {
				_and = ' and '
			}

			_queryString = _queryString + _and + _filterTypes[element]
		})

		state.filterString = _queryString
		state.filtersActive = !!state.filterString
	},

	// Filtering the job board without selecting a city
	SET_FILTER_WITHOUT_SELECTING_CITY(state, payload) {
		if (
			state.filterStringDistance === '' ||
			state.filterStringDistance === null
		) {
			if (payload.selected == null) {
				delete state.filters[payload.facet]
			} else {
				state.filters[payload.facet] = Array.isArray(payload.selected)
					? payload.selected
					: payload.selected.split()
			}

			let allFilters = []
			let allFiltersString = ''

			const keys = Object.keys(state.filters)
			keys.map((key) => {
				const filterArray = []
				let filterString = ''
				state.filters[key].map((selectedValue) => {
					// handle query string for numbers or strings, add quotes depending
					const filter =
						typeof selectedValue === 'number'
							? selectedValue
							: `'${selectedValue}'`
					return filterArray.push(
						`jobUmfaenge/any (job: job/jobUmfangName eq ${filter})`,
						`arbeitsbereiche/any (job: job/arbeitsbereich eq ${filter})`
					)
				})
				filterString += filterArray.join(' or ')
				return allFilters.push(filterString)
			})

			allFilters = allFilters.filter((f) => f.length !== 0)
			allFiltersString = allFilters.join(' and ')
			state.filterString = allFiltersString
			state.filtersActive = !!state.filterString
		}
	},

	SET_FILTERS_DISTANCE(state, payload) {
		if (payload.selected == null) {
			delete state.filterRadius[payload.facet]
		} else {
			state.filterRadius[payload.facet] = Array.isArray(payload.selected)
				? payload.selected
				: payload.selected.split()
		}

		let allFilters = []
		let allFiltersString = ''

		const keys = Object.keys(state.filterRadius)
		keys.map((key) => {
			const filterArray = []
			let filterStringDistance = ''
			state.filterRadius[key].map((selectedValue) => {
				const filtertest =
					typeof selectedValue === 'number'
						? selectedValue
						: `${selectedValue}`
				return filterArray.push(`${filtertest}`)
			})
			filterStringDistance += filterArray.join(' or ')
			return allFilters.push(filterStringDistance)
		})

		allFilters = allFilters.filter((f) => f.length !== 0)
		allFiltersString = allFilters.join(' and ')
		state.filterStringDistance = allFiltersString
		state.filtersActive = !!state.filterStringDistance
	},

	REMOVE_FILTERS_DISTANCE(state) {
		state.filterStringDistance = null /* default value for distance */
	},

	// Clear Filters if filterStringDistance = ""
	CLEAR_FILTERS(state) {
		state.filters = {}
		state.filterString = ''
		state.filterStringDistance = ''
		state.filterRadius = {}
		state.searchStringDistance = {}
	},

	SET_CURRENT_PAGE(state, page) {
		state.currentPage = page
	},

	SET_RESULTS_PER_PAGE(state, count) {
		state.resultsPerPage = count
	},

	SET_ORDERBY(state, value) {
		state.orderBy = value
	},

	SET_RESULTS_COUNT(state, count) {
		state.resultsCount = count
	},
	SET_SEARCH_DATA_COUNT(state, count) {
		state.keSearchResultsCount = count
	},

	SET_DATA_SET(state, datajobs) {
		state.datajobs = datajobs
	},

	SET_SELECTED_JOB_OFFER(state, offer) {
		state.selectedJobOffer = offer
	},

	loadingStatus(state, newLoadingStatus) {
		state.loadingStatus = newLoadingStatus
	},

	SET_SEARCH_DATA(state, searchData) {
		state.searchData = searchData
	},

	SET_SEARCH_QUERY_STRING(state, searchData) {
		state.searchQueryString = searchData
	},

	SET_SUBSIDIARY_JOB(state, SearchSubsidiary) {
		state.SearchSubsidiary = SearchSubsidiary
	},
	SET_LISTLOCATON(state, value) {
		state.listLocation = value
	},
	addToBookmark: (state, payload) => {
		let itemfound = state.tempbookmark.find((el) => el.id === payload.id)
		itemfound
		state.tempbookmark.push(payload)
	},
	removeOneFromBookmark: (state, payload) => {
		let index = state.tempbookmark.findIndex(el => el.id === payload.id)
		state.tempbookmark[index].item
		  ? state.tempbookmark[index].item--
		  : state.tempbookmark.splice(index, 1)
	},

	SET_ORT(state, ort) {
		state.ort = ort;
	 },
}
