import Cookies from 'js-cookie';

export default {
	userLocation: (state) => async (env) => {
		const cachedLatitude = Cookies.get('userLocationLatitude');
		const cachedLongitude = Cookies.get('userLocationLongitude');

		if (cachedLatitude && cachedLongitude) {
			return { lat: parseFloat(cachedLatitude), lng: parseFloat(cachedLongitude) };
		}

		try {
			const response = await fetch(`${process.env.TYPO3_API_BASEURL}location-service/get-location?timestamp=${Date.now()}`);
			const data = await response.json();

			// expire in 1 day
			Cookies.set('userLocationLatitude', data.location.latitude, { expires: 1 });
			Cookies.set('userLocationLongitude', data.location.longitude, { expires: 1 });

			return { lat: data.location.latitude, lng: data.location.longitude };
		} catch (error) {
			console.error(error);
			return { lat: 51.24664546487533, lng: 7.604947131029567 }; // Lüdenscheid as fallback
		}
	},
	locationLatitude(state) {
		return state.typo3.page.content.colPos0[0].content.data.content.contact
			.map.latitude
	},
	locationLongitude(state) {
		return state.typo3.page.content.colPos0[0].content.data.content.contact
			.map.longitude
	},
	loadingStatus(state) {
		return state.loadingStatus
	},
	sortedDivistions(state) {
		return state.subsidiary.sort(
			(a, b) => parseFloat(a.plz) - parseFloat(b.plz)
		)
	},
	bookmarkItems: (state) => {
		if (!state.tempbookmark.length) return []
		return state.tempbookmark.map((item) => {
			return {
				id: item.id,
			}
		})
	},
}
