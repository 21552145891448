import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { de } from '@braid/vue-formulate-i18n';
import FormulateInputSelect from './FormulateInputSelect';
import FormulateAutocomplete from './FormulateAutocomplete'
import VueFormulateDatepickerPlugin from "vue-formulate-datepicker";

Vue.use(VueFormulate, {
	plugins: [de, FormulateInputSelect, VueFormulateDatepickerPlugin, FormulateAutocomplete ],
	locale: 'de',
	locales: {
		en: {
			required ({vm, name}) {
				return `The field "${vm?.label || name}" is required!`;
			}
		},
		de: {
			required ({vm, name}) {
				return `Das Feld "${vm?.label || name}" muss ausgefüllt werden!`;
			}
		}
	}
});
