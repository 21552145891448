/**
 * Makes the first character of the given string uppercase
 *
 * @param {String} str
 * @returns {String}
 */
const capitalize = (str) => {
	if (typeof str !== 'string') {
		return ''
	}
	return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Converts snake_case'd strings to camelCase, e.g.:
 * tx_myplugin_pi1 => txMypluginPi1
 *
 * @param {String} str
 * @returns {String}
 */
const snakeCaseToCamelCase = function (str) {
	return str.replace(/_{1,}/g, '_').replace(/_([a-zA-Z0-9])/g, (s) => {
		return s[1].toUpperCase()
	})
}

/**
 * Returns a random string representing a uuid
 *
 * @returns {String}
 * @see https://stackoverflow.com/a/2117523
 */
const uuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
		/[xy]/g,
		function (c) {
			var r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		}
	)
}

/**
 * Returns a truncated string with the specified maximum length.
 * If respectWordBoundaries is set to true (which is the default behaviour)
 * words won't be cut of, so the given text can be properly read.
 *
 * @param {String} value
 * @param {Number} length
 * @param {String} suffix
 * @param {Boolean} respectWordBoundaries
 * @returns {String}
 */
const truncateText = (
	value,
	length,
	suffix = '…',
	respectWordBoundaries = true
) => {
	if (value.length <= length) {
		return value
	}

	let trimmed = value.substr(0, length)
	if (respectWordBoundaries) {
		trimmed = trimmed.substr(
			0,
			Math.min(trimmed.length, trimmed.lastIndexOf(' '))
		)
	}
	return trimmed + suffix
}

// Convert the url from .json to .html
const replaceApiUrlToNuxtUrl = (url) => {
	return url.replace(
		/^(https?:\/\/[^\/]+)?(\/)?api\/(.+)\.json(\?.+)?$/g,
		'$1$2$3.html$4'
	)
}

// Convert the url from .json to .html in v-html Directives
const replaceApiUrlInHtml = (html) => {
	return html.replace(
		/(href|src)="([^"]+)"/g,
		function (matches, attribute, url) {
			return attribute + '="' + replaceApiUrlToNuxtUrl(url) + '"'
		}
	)
}

export {
	capitalize,
	snakeCaseToCamelCase,
	uuid,
	truncateText,
	replaceApiUrlToNuxtUrl,
	replaceApiUrlInHtml,
}
