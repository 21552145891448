import Vue from 'vue';
import {
	truncateText,
	replaceApiUrlToNuxtUrl,
	replaceApiUrlInHtml,
} from '~/plugins/utilities/StringUtility';

Vue.filter('truncateText', truncateText);
Vue.filter('replaceApiUrlToNuxtUrl', replaceApiUrlToNuxtUrl);
Vue.filter('replaceApiUrlInHtml', replaceApiUrlInHtml);
