import FormulateInputSelect from '../components/atoms/forms/select/FormulateInputSelect';

// extend FormulateInputSelect
export default function (formulate) {
	formulate.extend({
		components: {
			FormulateInputSelect
		},
			library: {
				select: {
					classification: 'select',
					component: 'FormulateInputSelect'
				}
		}
	})
}

