import data from "~/api/subsidiary.json";

export default () => ({
	results: [],
	checkbox_facets: [],
	dropdown_facets: [],
	range_facets: [],
	filters: {},
	filterRadius: {},
	searchString: '',
	filterString: '',
	filterStringDistance: '',
	searchStringDistance: '',
	searchQueryString: '',
	currentPage: 1,
	resultsPerPage: 10,
	orderBy: '',
	resultsCount: 0,
	filtersActive: false,
	datasubsidirayJobs: [],
	datajobs: data.value,
	subsidiary: data.values,
	searchData: data,
	loadingStatus: false,
	selectedJobOffer: null,
	tempbookmark: [],
	ort: '',
})
