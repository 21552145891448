import AzureSearch from "azure-search";
import axios from "axios";

export default {
  executeSearch({ state, commit }) {
    commit("loadingStatus", true);
    // don't combine field "filter" and the field "search"
    let searchString =
      state.filterStringDistance !== null && state.filterStringDistance !== ""
        ? ""
        : state.searchString;

    let query = {
      search: `${searchString}`,
      filter: state.filterString,
      top: state.resultsPerPage,
      skip: (state.currentPage - 1) * state.resultsPerPage,
      facets: ["jobUmfaenge/jobUmfangName", "arbeitsbereiche/arbeitsbereich"],
      orderby: "aktualisiertAm desc",
      count: true,
    };

    const searchClient = AzureSearch({
      url: this.$config.jobsearchUrl,
      key: this.$config.jobsearchKey,
      version: this.$config.jobsearchVersion,
    });

    searchClient.search(
      this.$config.jobsearchIndexName,
      query,
      (err, results, raw) => {
        if (raw === null) {
          return false;
        }
        commit("loadingStatus", false);
        commit("SET_RESULTS", raw.value);
        commit("SET_RESULTS_COUNT", raw["@odata.count"]);
        commit("SET_CHECKBOX_FACETS", raw["@search.facets"]);
        commit("SET_DROPDOWN_FACETS", raw["@search.facets"]);
      }
    );
  },
  executeSearchSubsidiary({ state, commit }) {
    const searchClient = AzureSearch({
      url: this.$config.jobsearchUrl,
      key: this.$config.jobsearchKey,
      version: this.$config.jobsearchVersion,
    });
    commit("loadingStatus", true);
    searchClient.search(
      this.$config.jobsearchIndexName,
      {
        search: `${state.SearchSubsidiary}`,
        searchMode: "all",
        queryType: "full",
        // filter: `nlCode eq ${state.SearchSubsidiary}`,
        orderby: "aktualisiertAm desc",
        count: true,
      },
      (err, results, raw) => {
        if (raw === null) {
          return false;
        }
        commit("loadingStatus", false);
        commit("SET_RESULTS", raw.value);
        commit("SET_RESULTS_COUNT", raw["@odata.count"]);
      }
    );
  },
  setSearchString({ dispatch, commit }, value = "*") {
    commit("SET_CURRENT_PAGE", 1);
    commit("SET_SEARCHSTRING", value);
    commit("SET_FILTERS", "");
    dispatch("executeSearch");
  },
  setSearchStringDistance({ dispatch, commit }, value) {
    commit("SET_CURRENT_PAGE", 1);
    commit("SET_SEARCHSTRING_DISTANCE", value);
    dispatch("executeSearch");
  },
  setSearchQueryString({ commit }, value = "") {
    commit("SET_CURRENT_PAGE", 1);
    commit("SET_SEARCH_QUERY_STRING", value);
  },
  setFilter({ dispatch, commit }, payload) {
    commit("SET_CURRENT_PAGE", 1);
    commit("SET_FILTERS", payload);
    commit("SET_FILTER_WITHOUT_SELECTING_CITY", payload);
    dispatch("executeSearch");
  },
  setFilterDistance({ dispatch, commit }, payload) {
    commit("SET_CURRENT_PAGE", 1);

    if (payload.selected === null) {
      commit("REMOVE_FILTERS_DISTANCE", payload);
    } else {
      commit("SET_FILTERS_DISTANCE", payload);
    }

    commit("SET_FILTERS", "");
    dispatch("executeSearch");
  },
  SearchSubsidiary({ dispatch, commit }, value) {
    commit("SET_SUBSIDIARY_JOB", value);
    dispatch("executeSearchSubsidiary");
  },
  clearFilters({ dispatch, commit }, page) {
    commit("CLEAR_FILTERS");
    commit("SET_CURRENT_PAGE", page);
    dispatch("executeSearch");
  },

  setCurrentPage({ dispatch, commit }, page) {
    commit("SET_CURRENT_PAGE", page);
    dispatch("executeSearch");
  },

  setResultsPerPage({ dispatch, commit }, count) {
    commit("SET_RESULTS_PER_PAGE", count);
    dispatch("executeSearch");
  },

  setOrderBy({ dispatch, commit }, payload) {
    commit("SET_CURRENT_PAGE", 1);
    commit("SET_FILTERS", payload);
    dispatch("executeSearch");
  },

  setFilterString({ dispatch, commit }) {
    commit("SET_FILTERSTRING");
    dispatch("fetchKeSearch");
  },
  setResultsKeSearchPerPage({ dispatch, commit }, count) {
    commit("SET_SEARCH_DATA_COUNT", count);
    dispatch("fetchKeSearch");
  },

  selectJobOffer({ commit }, payload) {
    commit("SET_SELECTED_JOB_OFFER", payload);
  },

  // Create DataJobs for Dynamic Pages data is not lost during reload.
  async createDataJobs({ commit }) {
    commit("loadingStatus", true);

    var data = JSON.stringify({
      count: true,
      top: 909,
      searchMode: "all",
      queryType: "full",
      facets: ["jobUmfaenge/jobUmfangName"],
    });

    var config = {
      method: "post",
      url: `${this.$config.jobsearchUrl}/indexes/isd-jobs-sql/docs/search?api-version=${this.$config.jobsearchVersion}`,
      headers: {
        "api-key": this.$config.jobsearchKey,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config)
      .then((response) => {
        commit("SET_DATA_SET", response.data.value);
        commit("loadingStatus", false);
      })
      .catch((error) => {
        this.error.push(error);
        console.log(error);
      });
  },

  async fetchKeSearch({ commit }) {
    commit("loadingStatus", true);

    await axios
      .get(
        `${this.$config.keSearchResultsUrl}/suche.html?tx_kesearch_pi1[headless_ce]=1601&tx_kesearch_pi1[sword]=${this.state.searchQueryString}&tx_kesearch_pi1[page]=${this.state.currentPage}&no_cache=1`
      )
      .then((response) => {
        commit("SET_SEARCH_DATA", response.data);
        commit("loadingStatus", false);
        commit("SET_SEARCH_DATA_COUNT", response.data.pagebrowser.total);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
