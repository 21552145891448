import Vue from 'vue'
import { registerBackendLayouts } from '~typo3/plugins/layouts';
import BeDefault from '~/layouts/backend/BeDefault';
import BeSinglecolumn from '~/layouts/backend/BeSinglecolumn';
import Be2Columns from '~/layouts/backend/Be2columns';
import BeHeroImage from '~/layouts/backend/BeHeroImage';
import BeJobmarket from '~/layouts/backend/BeJobmarket';
import BeLandingpage from '~/layouts/backend/BeLandingpage';
import BeSubsidiaries from '~/layouts/backend/BeSubsidiaries';
import BeNewsDetail from '~/layouts/backend/BeNewsDetail';

const layouts = {
	BeDefault,
	Be2Columns,
	BeSinglecolumn,
	BeHeroImage,
	BeJobmarket,
	BeLandingpage,
	BeSubsidiaries,
	BeNewsDetail
}

export default ({ app }) => {
  Vue.use(registerBackendLayouts, {
    context: app,
    layouts
  })
}
