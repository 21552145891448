import Vue from "vue";

import HtmlParser from "~/components/atoms/utilities/HtmlParser.vue";

import CeBullets from "~/components/atoms/lists/BulletsList";
import CeHeader from "~/components/atoms/text/Headers";
import CeText from "~/components/atoms/text/CeText";
import CeImage from "~/components/atoms/images/Images";
import CeMediaFile from "~/components/atoms/media/File";
import CeMediaHeadvisual from "~/components/atoms/media/FileHeadvisual";
import MediaGallery from "~/components/atoms/media/Gallery";
import CeMask_blockquote from "~/components/atoms/text/Blockquote";
import CeMask_social_share from "~/components/molecules/social/SocialShare";

import CeFrame from "~/components/molecules/utilities/Frame";
import CeTextpic from "~/components/molecules/media/TextImage";
import CeUploads from "~/components/molecules/media/Downloads";
// import CeMask_parallax_images from '~/components/molecules/media/ParallaxImages';

import CeMenuPages from "~/components/organisms/teasers/TeaserMenu";
import CeHeadvisual from "~/components/organisms/hero/Headvisual";
import CeMask_animated_counter from "~/components/organisms/animation/AnimatedCounter";
import HtmlTeaser from "~/components/organisms/sections/BubbleTeasersHtml";

import CeStructured_content from "~/components/organisms/layout/StructuredContent";
import CeMultiColumns from "~/components/organisms/layout/StructuredContent/MultiColumns";
const CeMultiColumns2columns = CeMultiColumns;
const CeMultiColumns3columns = CeMultiColumns;
const CeMultiColumns4columns = CeMultiColumns;
import CeContentContainer from "~/components/organisms/layout/StructuredContent/ContentContainer";
import CeAccordion from "~/components/organisms/layout/StructuredContent/Accordion";
import CeReadMore from "~/components/organisms/layout/StructuredContent/ReadMore";
import CeBubbleTeaser from "~/components/organisms/layout/StructuredContent/BubbleTeaser";
import CeHighlightBox from "~/components/organisms/layout/StructuredContent/HighlightBox";
import CeContentSlider from "~/components/organisms/layout/StructuredContent/ContentSlider";
import CeGermanymap from "~/components/organisms/layout/StructuredContent/Germanymap";
import CeImageSlider from "~/components/organisms/layout/StructuredContent/ImageSlider";
import CeMask_etracker_check from "~/components/atoms/forms/switches/EtrackerSwitch";
import CeMask_adventcalendar from "~/components/organisms/mask-scripts/AdventCalendarScript";

import CeMask_locations_contacts from "~/components/organisms/contacts/LocationsContacts";
import CeMask_page_contact_form from "~/components/organisms/contacts/PageContactForm";
import CeMask_locations_contacts_google_maps from "~/components/organisms/contacts/LocationGoogleMaps";

import CeNews_pi1 from "~/components/organisms/news/CeNews_pi1";
import CePowermail_pi1 from "~/components/molecules/forms/ContactFormPowermail";
import CeMask_jobmarket from "~/components/organisms/jobmarket/JobMarketContainer";
import CeMask_jobmarketbookmarks from "~/components/organisms/jobmarket/bookmarks/Bookmarklist";
import CeMask_bewerbungsformular from "~/components/organisms/jobmarket/forms/InitiativeApplication";
import CeMask_applicantform from "~/components/organisms/jobmarket/forms/ApplicantForm";
import CeMask_jobfinder from "~/components/organisms/jobsearchcategory/JobFinder";
import CeMask_job_search_category from "~/components/organisms/jobsearchcategory/JobSearchCategoryContainerResults";

import CeIsdsitepackage_pisubsidiary from "~/components/organisms/subsidiary/isdsitepackage_pisubsidiary";
import CeIsdsitepackage_pisubsidiaries from "~/components/organisms/subsidiaries/SubsidiariesGoogleMaps";
import CeKe_search_pi1 from "~/components/organisms/kesearch/SearchField.vue";
import CeKe_search_pi2 from "~/components/organisms/kesearch/SearchResults.vue";

import CeMask_google_maps_header from "~/components/organisms/subsidiaries/geolocation/GeolocationHeader";
import CeMask_google_maps_content from "~/components/organisms/subsidiaries/geolocation/Geolocation";

const components = {
  HtmlParser,

  CeBullets,
  CeHeader,
  CeText,
  CeImage,
  CeMediaFile,
  CeMediaHeadvisual,
  MediaGallery,
  CeMask_blockquote,
  CeMask_social_share,

  CeFrame,
  CeTextpic,
  CeUploads,

  CeMenuPages,
  CeHeadvisual,
  CeMask_animated_counter,
  HtmlTeaser,

  CeStructured_content,
  CeMultiColumns,
  CeMultiColumns2columns,
  CeMultiColumns3columns,
  CeMultiColumns4columns,
  CeContentContainer,
  CeAccordion,
  CeReadMore,
  CeBubbleTeaser,
  CeHighlightBox,
  CeContentSlider,
  CeGermanymap,
  CeImageSlider,
  CeMask_locations_contacts,
  CeMask_page_contact_form,
  CeMask_locations_contacts_google_maps,
  // CeMask_parallax_images,

  CeNews_pi1,
  CePowermail_pi1,
  CeMask_jobmarket,
  CeMask_jobmarketbookmarks,
  CeMask_bewerbungsformular,
  CeMask_applicantform,
  CeMask_jobfinder,
  CeMask_job_search_category,
  CeMask_etracker_check,
  CeMask_adventcalendar,

  CeIsdsitepackage_pisubsidiary,
  CeIsdsitepackage_pisubsidiaries,
  CeKe_search_pi1,
  CeKe_search_pi2,

  CeMask_google_maps_header,
  CeMask_google_maps_content
};

export default ({ app }) => {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
  });
};
