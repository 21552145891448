import contact from "~/api/contact";
import jobApplication from '~/api/job-application';

export default (context, inject) => {
    const factories = {
     contact: contact(context.$axios),
     jobApplication: jobApplication(context.$axios),
    };

    inject('api', factories);
}
