import FormulateAutocomplete from '../components/atoms/forms/select/FormulateAutocomplete';

export default function (formulateInstance) {
	formulateInstance.extend({
		components: {
			FormulateAutocomplete
		},
		library: {
			autocomplete: {
				classification: 'text',
				component: 'FormulateAutocomplete'
			}
		}
	})
}
