// Docs https://github.com/FortAwesome/vue-fontawesome

import Vue from 'vue';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/pro-regular-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
// Fix large icon flash during initial page load.
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(fal);

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);

import customIconDefinitions from '~/assets/custom-icon-definitions/index';
for (const [_, iconDefinition] of Object.entries(customIconDefinitions)) {
	library.add(iconDefinition);
};
