// Docs https://github.com/xkjyeah/vue-google-maps
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

// Note: the name "cluster" below is the one to use in the template tags
// Using Cluster requires marker-clusterer-plus to be installed.
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

// Note: the name "cluster" below is the one to use in the template tags
Vue.component('gmap-cluster', GmapCluster)

export default (context, inject) => {
	Vue.use(VueGoogleMaps, {
		load: {
			key: context.isDev
				? 'AIzaSyCg-Il8ZubgmZWZBT7gckSaZtfnOMLPRoc'
				: // The API key should be either this
				  'AIzaSyCg-Il8ZubgmZWZBT7gckSaZtfnOMLPRoc',
			// This is required if you use the Autocomplete plugin
			libraries: 'places,geometry',
			region: 'DE',
			language: 'de-DE',
			// OR: libraries: 'places,drawing'
			// OR: libraries: 'places,drawing,visualization'
			// (as you require)

			//// If you want to set the version, you can do so:
			// v: '3.26',
		},

		//// If you intend to programmatically custom event listener code
		//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
		//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
		//// you might need to turn this on.
		// autobindAllEvents: false,

		//// If you want to manually install components, e.g.
		//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
		//// Vue.component('GmapMarker', GmapMarker)
		//// then set installComponents to 'false'.
		//// If you want to automatically install all the components this property must be set to 'true':
		installComponents: true,
	})
}
