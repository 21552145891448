/**
 * This funciton will check, wheter the icon.prefix and icon.iconName properties are present and not empty.
 *
 * @param {Object} obj
 */
const isIconPresentInObject = (object) => {
	return (
		typeof object == 'object'
		&& object?.icon?.prefix?.length > 0
		&& object?.icon?.iconName?.length > 0
	);
};

export {
	isIconPresentInObject,
};
